<template>
  <AbstractDataTable
    :headers="tableHeaders"
    :items="otherFunctions"
    :total-count="totalCount"
    :current-page="currentPage"
    :on-page-change="onPageChange"
    :button-required-permissions="requiredPermissions"
    store-name="otherFunction"
    table-css-class="otherFunction-table"
    >
    <template #tableBody="{ item }">
      <TitleColumn
        :title-header="tableHeaders[0].name"
        :title-value="item.id"
        subtitle
      />
      <TitleColumn
        :title-header="tableHeaders[1].name"
        :title-value="item.name"
      />
    </template>
  </AbstractDataTable>
</template>

<script>
import AbstractDataTable from '@/components/table/AbstractDataTable'
import TitleColumn from '@/components/table/columns/TitleColumn'
import PermissionService from '@/services/PermissionService'

export default {
  name: 'OtherFunctionTable',
  components: {
    TitleColumn,
    AbstractDataTable
  },
  data () {
    return {
      requiredPermissions: PermissionService.REQUIRED_PERMISSIONS.SYSTEM_OTHER_FUNCTION_PERMISSIONS
    }
  },
  computed: {
    tableHeaders () {
      return [
        { name: 'Id' },
        { name: this.$t('otherFunction.name') },
        { name: this.$t('actions') }
      ]
    },
    otherFunctions () {
      return this.$store.getters['otherFunction/list']
    },
    totalCount () {
      return this.$store.getters['otherFunction/totalCount']
    },
    currentPage () {
      return this.$store.getters['otherFunction/page']
    }
  },
  methods: {
    getOtherFunctions () {
      this.$store.dispatch('otherFunction/fetch')
    },
    onPageChange (page) {
      this.$store.commit('otherFunction/setPage', page)
      this.getOtherFunctions()
    }
  },
  created () {
    this.getOtherFunctions()
  }
}
</script>
<style lang="scss">
  .otherFunction-table {
    &__thead,
    &__tr {
      grid-template-columns: rem(80px) repeat(auto-fit, minmax(100px, 1fr));
    }
  }
</style>
