<template>
  <section>
    <OtherFunctionFilter />
    <Preloader v-if="callingAPI" />
    <OtherFunctionTable />
  </section>
</template>

<script>
import { mapState } from 'vuex'
import Preloader from '@/components/preloader/Preloader'
import OtherFunctionFilter from '@/components/filter/FilterOtherFunction'
import OtherFunctionTable from '@/components/table/OtherFunctionTable'

export default {
  name: 'OtherFunctionListView',
  components: {
    OtherFunctionTable,
    Preloader,
    OtherFunctionFilter
  },
  computed: {
    ...mapState([
      'callingAPI'
    ])
  }
}
</script>
