<template>
  <AbstractFilter
    :on-filter="filterOtherFunctions"
    :on-filter-reset="resetFilter"
    :button-required-permissions="requiredPermissions"
    create-new-route-name="otherFunction_new"
    filter-panel-class="filter-panel-otherFunction"
  >
    <Input
      v-model="filter.name"
      id="filter_name"
      :label="$t('otherFunction.name')"
    />
    <Input
      v-model="filter.id"
      id="filter_id"
      label="Id"
    />
  </AbstractFilter>
</template>

<script>

import AbstractFilter from '@/components/filter/AbstractFilter'
import Input from '@/components/form/inputs/Input'
import PermissionService from '@/services/PermissionService'

export default {
  name: 'OtherFunctionFilter',
  components: {
    AbstractFilter,
    Input
  },
  data () {
    return {
      isLoading: false,
      filter: { id: '', name: '' },
      requiredPermissions: PermissionService.REQUIRED_PERMISSIONS.SYSTEM_OTHER_FUNCTION_PERMISSIONS
    }
  },
  methods: {
    filterOtherFunctions () {
      this.$store.commit('otherFunction/setPage', 1)
      this.$store.commit('otherFunction/setFilter', this.filter)
      this.$store.dispatch('otherFunction/fetch')
    },
    resetFilter () {
      this.$store.commit('otherFunction/resetFilter')
      this.filter = this.$store.getters['otherFunction/filter']
      this.$store.dispatch('otherFunction/fetch')
    }
  },
  beforeMount () {
    this.filter = this.$store.getters['otherFunction/filter']
  }
}
</script>

<style lang="scss">
.filter-panel-otherFunction {
  @include bp(7) {
    grid-template-columns: 1fr;
  }
  @include bp(12) {
    grid-template-columns: 1fr 1fr;
  }
}
</style>
